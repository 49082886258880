<template>
  <div class="col-lg-5 office-info">
    <div class="tab-nvas">
      <ul class="nav nav-pills">
        <li
          v-for="(item, index) in items"
          :key="index"
          :class="{ active: item.expanded }"
        >
          <a
            data-toggle="tab"
            :href="`#id${item.id}`"
            :aria-expanded="item.expanded"
          >
            {{ item.title }}
          </a>
        </li>
        <!-- <li>
          <a data-toggle="tab" href="#id2" aria-expanded="false"> Mazatlan </a>
        </li> -->
      </ul>
    </div>
    <div class="tab-content pad-all-20p txt-center-mobile">
      <div
        :id="`id${item.id}`"
        class="tab-pane"
        :class="{ active: item.expanded }"
        v-for="(item, index) in items"
        :key="index"
      >
        <ul>
          <li>
            <div class="icon">
              <i class="fas fa-map-marker-alt"></i>
            </div>
            <div class="info">
              <p>
                Direccion
                <span>{{ item.address }}</span>
              </p>
            </div>
          </li>
          <li>
            <div class="icon">
              <i class="fas fa-envelope-open"></i>
            </div>
            <div class="info">
              <p>
                Email Contacto
                <span>{{ item.email }}</span>
              </p>
            </div>
          </li>
          <li>
            <div class="icon">
              <i class="fas fa-mobile-alt"></i>
            </div>
            <div class="info">
              <p>
                WhatsApp de Contacto
                <span>{{ item.phone }}</span>
              </p>
            </div>
          </li>
        </ul>
      </div>
      <!-- <div id="id2" class="tab-pane">
        <ul>
          <li>
            <div class="icon">
              <i class="fas fa-map-marker-alt"></i>
            </div>
            <div class="info">
              <p>
                Direccion
                <span>Av. Moctezuma 342 Mazatlan Sinaloa.</span>
              </p>
            </div>
          </li>
          <li>
            <div class="icon">
              <i class="fas fa-envelope-open"></i>
            </div>
            <div class="info">
              <p>
                Email Contacto
                <span>hello@enfoquelaboral.mx</span>
              </p>
            </div>
          </li>
          <li>
            <div class="icon">
              <i class="fas fa-mobile-alt"></i>
            </div>
            <div class="info">
              <p>
                WhatsApp de Contacto
                <span>+52 33 3121 2647</span>
              </p>
            </div>
          </li>
        </ul>
      </div> -->
      <!-- <div id="id3" class="tab-pane">
        <ul>
          <li>
            <img src="assets/img/office/3.jpg" alt="Thumb" />
          </li>
          <li>
            <div class="icon">
              <i class="fas fa-map-marker-alt"></i>
            </div>
            <div class="info">
              <p>
                Our Location
                <span>Malina City, MN 567894</span>
              </p>
            </div>
          </li>
        </ul>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          id: 1,
          title: "Corporativo GDL",
          expanded: true,
          address: "Av. Moctezuma 342 Zapopan Jal.",
          email: "Hello@Enfoquelaboral.Mx",
          phone: "+52 33 3121 2647",
        },

      ],
    };
  },
};
</script>

<style  scoped>
</style>
