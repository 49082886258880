<template>
  <div class="col-lg-7 contact-form">
    <div class="content">
      <div class="heading pt-3">
        <h3>¡Contáctanos! nos gustaria escucharte</h3>
      </div>
      <form @submit.prevent="save" class="contact-form">
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <input
                v-model.trim="editedItem.name"
                class="form-control"
                placeholder="Nombre Completo"
                type="text"
              />
              <span class="alert-error"></span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <input
                v-model.trim="editedItem.email"
                class="form-control"
                placeholder="Email*"
                type="email"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <input
                v-model.trim="editedItem.phone"
                class="form-control"
                placeholder="Celular"
                type="text"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group comments">
              <textarea
                v-model="editedItem.comment"
                class="form-control"
                placeholder="Escribe tus comentarios*"
              ></textarea>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <v-alert
              v-for="(error, idx) in getErrors"
              :key="idx"
              dense
              text
              type="error"
            >
              {{ error }}
            </v-alert>
            <v-alert v-if="show_alert" dense text type="success">
              Nos pondremos en contacto lo antes posible, Gracias!
            </v-alert>
            <v-btn
              color="primary"
              depressed
              :loading="isloading"
              type="submit"
              name="submit"
              id="submit"
            >
              Enviar Informacion <i class="fa fa-paper-plane"></i>
            </v-btn>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import * as yup from "yup";
export default {
  data() {
    return {
      show_alert: false,
      isloading: false,
      editedItem: {
        name: "",
        email: "",
        phone: "",
        comment: "",
      },
      defaultItem: {
        name: "",
        email: "",
        phone: "",
        comment: "",
      },
    };
  },
  watch: {
    show_alert() {
      if (this.show_alert) {
        setTimeout(() => {
          this.show_alert = false;
        }, 4000);
      }
    },
  },
  created() {
    this.setErrors("clear");
  },
  computed: {
    async validateForm() {
      this.setErrors("clear");

      const schemaContact = yup.object().shape({
        comment: yup.string().required("El commentario es requerido"),
        phone: yup.string().required("El telefono es requerido"),
        email: yup
          .string()
          .email("El email debe ser válido")
          .required("El email es requerido"),
        name: yup.string().required("El nombre es requerido"),
      });

      try {
        await schemaContact.validate(this.editedItem);
        return true;
      } catch (error) {
        this.setErrors(error.errors[0]);
        return false;
      }
    },
    ...mapGetters("comment", ["getErrors"]),
  },
  methods: {
    send() {},
    ...mapActions("comment", ["createComment", "setErrors"]),

    async save() {
      if (await this.validateForm) {
        this.isloading = true;
        await this.createComment(this.editedItem);
        // this.isloading = false;
        if (this.getErrors.length == 0) {
          this.show_alert = true;
          this.editedItem = Object.assign({}, this.defaultItem);
        }
        this.isloading = false;
      }
    },
  },
};
</script>
