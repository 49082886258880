<template>
  <div class="contact-form-area default-padding">
    <div class="container">
      <div class="row">
        <ContactForm />
        <ContactInfo />
      </div>
    </div>
  </div>
</template>

<script>
import ContactForm from "../components/contact/ContactForm.vue";
import ContactInfo from "../components/contact/ContactInfo.vue";
export default {
  components: { ContactForm, ContactInfo },
  head: {
    // To use "this" in the component, it is necessary to return the object through a function
    title: function () {
      return {
        inner: "Contácto",
      };
    },
  },
};
</script>

<style lang="scss" scoped>
</style>